import * as c from '@chakra-ui/react'
import { Flex } from '@retriever-ui/react'
import * as dateFns from 'date-fns'
import React from 'react'
import { SearchContext } from 'src/components/search-v2/search-context'
import { search_events } from 'src/components/search-v2/search-machine'
import dateUtils, { format } from 'src/utils/date'

import { FieldsContext } from '../../../fields/fields-context'
import { fields_events } from '../../../fields/fields-machine'
import { DateContext } from './date-context'
import { date_events } from './date-machine'

interface DateComponentProps {
  date: Date
  blockedDates?: string[]
  currentDate: Date
  index: number
  onStart?: (date: Date) => void
  onEnd?: (date: Date) => void
}

export const DateComponent = ({
  date,
  currentDate,
  blockedDates,
  index,
  onStart,
  onEnd,
}: DateComponentProps) => {
  const fieldsState = React.useContext(FieldsContext)
  const searchState = React.useContext(SearchContext)
  const dateState = React.useContext(DateContext)

  const isBlockDate = React.useMemo(() => {
    const formattedDate = dateUtils.format(date, 'yyyy-MM-dd')
    const formattedCurrentDate = dateUtils.format(new Date(), 'yyyy-MM-dd')
    return (
      formattedDate !== formattedCurrentDate &&
      blockedDates?.some((blocked) => blocked === formattedDate)
    )
  }, [blockedDates, date])

  const isStartSelected = dateFns.isEqual(
    date,
    dateUtils.create(searchState.context.start!)
  )
  const isEndSelected = dateFns.isEqual(
    date,
    dateUtils.create(searchState.context.end!)
  )
  const isSameMonth = dateFns.isSameMonth(currentDate, date)
  const isHovered = dateFns.isEqual(date, dateState.context.hoverDate!)

  const inHover = React.useMemo(() => {
    if (!searchState.context.start && !dateState.context.hoverDate) {
      return false
    }

    if (searchState.context.end) {
      return dateUtils.isInsideBetween(
        date,
        dateUtils.create(searchState.context.start!),
        dateUtils.create(searchState.context.end)
      )
    }

    return dateUtils.isInsideBetween(
      date,
      dateUtils.create(searchState.context.start!),
      dateState.context.hoverDate!
    )
  }, [searchState, dateState, date])

  const selectStart = () => {
    searchState.send(search_events.set_date_end, { end: undefined })
    searchState.send(search_events.set_date_start, { start: date })
    fieldsState.send(fields_events.to_end)
    onStart?.(date)
  }
  const selectEnd = () => {
    searchState.send(search_events.set_date_end, { end: date })
    onEnd?.(date)
  }

  const handleClickDate = () => {
    if (dateFns.isEqual(searchState.context.start!, date)) {
      return selectStart()
    }
    if (!searchState.context.start) {
      return selectStart()
    }

    if (fieldsState.valid.start) {
      return selectStart()
    }

    // when select a date less than date start
    if (
      dateFns.isBefore(
        date,
        dateUtils.create(searchState.context.end ?? searchState.context.start)
      )
    ) {
      return selectStart()
    }

    if (fieldsState.valid.end) {
      return selectEnd()
    }

    return selectEnd()
  }

  const onMouseEnter = () => {
    if (searchState.context.end) {
      return
    }

    if (searchState.context.start) {
      if (dateFns.isAfter(date, dateUtils.create(searchState.context.start!))) {
        return dateState.send(date_events.hover, { hoverDate: date })
      }
    }
  }

  const onMouseLeave = () => dateState.send(date_events.blur)

  const isLastOfWeek = (index + 1) % 7 === 0 || dateFns.isLastDayOfMonth(date)
  const isFirstOfWeek = index % 7 === 0 || dateFns.isFirstDayOfMonth(date)

  const leftGradient =
    'linear-gradient(90deg, primary.100 50%, transparent 50%)'
  const rightGradient =
    'linear-gradient(90deg, transparent 50%, primary.100 50%)'

  const isEndHovered = isEndSelected || isHovered
  const radiusRight = (inHover && isLastOfWeek) || isEndHovered ? 20 : 0
  const radiusLeft = (inHover && isFirstOfWeek) || isStartSelected ? 20 : 0

  const barBackground = inHover
    ? isEndHovered
      ? leftGradient
      : isStartSelected
      ? rightGradient
      : 'primary.100'
    : 'transparent'

  const circleBackground = isStartSelected
    ? 'primary.500'
    : isEndSelected
    ? 'black.0'
    : isHovered
    ? 'primary.100'
    : 'transparent'

  if (!isSameMonth) return <div />

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  if (dateFns.isBefore(date, today))
    return (
      <c.Flex
        minH="32px"
        maxH="32px"
        minW="32px"
        maxW="32px"
        borderRadius="16px"
        align="center"
        justify="center"
      >
        <c.Text color="black.300" fontSize={14} fontWeight={500}>
          {format(date, 'dd')}
        </c.Text>
      </c.Flex>
    )

  if (isBlockDate)
    return (
      <c.Flex
        minH="32px"
        maxH="32px"
        minW="32px"
        maxW="32px"
        borderRadius="16px"
        cursor="not-allowed"
        align="center"
        justify="center"
      >
        <c.Text
          color="black.300"
          fontSize={14}
          fontWeight={500}
          textDecoration="line-through"
        >
          {format(date, 'dd')}
        </c.Text>
      </c.Flex>
    )

  return (
    <Flex
      onClick={handleClickDate}
      bg={barBackground}
      radiusTopRight={radiusRight}
      radiusBottomRight={radiusRight}
      radiusTopLeft={radiusLeft}
      radiusBottomLeft={radiusLeft}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      justifyContent="center"
      minH="32px"
      maxH="32px"
      minW="48px"
      maxW="48px"
      userSelect="none"
    >
      <c.Flex
        borderRadius="18px"
        minH="32px"
        maxH="32px"
        minW="32px"
        maxW="32px"
        bg={circleBackground}
        border="2px solid"
        borderColor={isEndSelected ? 'primary.500' : 'transparent'}
        cursor="pointer"
        align="center"
        justify="center"
        alignSelf="flex-end"
        _hover={{ border: '2px solid primary.500' }}
      >
        <c.Text
          color={isStartSelected ? 'black.0' : 'black.500'}
          fontSize={14}
          fontWeight={500}
        >
          {format(date, 'dd')}
        </c.Text>
      </c.Flex>
    </Flex>
  )
}

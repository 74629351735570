import { useTranslation } from 'react-i18next'
import { FigerprintIcon } from 'src/components/navbar/icons/fingerprint.icon'
import { HelpIcon } from 'src/components/navbar/icons/help.icon'
import { MessageCircleIcon } from 'src/components/navbar/icons/message-circle.icon'
import { MonitorIcon } from 'src/components/navbar/icons/monitor.icon'

export interface HelpConfigItem {
  id: string
  label: string
  path: string
  Icon: (props: any) => JSX.Element
}

export function HelpItemsConfig() {
  const { t } = useTranslation('components/nav')

  const notLoggedHelpItems: HelpConfigItem[] = [
    {
      id: 'quem-somos',
      label: t('components/nav:WHO'),
      path: 'https://lps.housi.com/quemsomos/',
      Icon: FigerprintIcon,
    },
    {
      id: 'blog-housi',
      label: t('components/nav:BLOG'),
      path: 'https://blog.housi.com/',
      Icon: MonitorIcon,
    },
    {
      id: 'faq',
      label: t('components/nav:FAQ'),
      path: 'https://lps.housi.com/faq/',
      Icon: HelpIcon,
    },
  ]

  const loggedHelpItems: HelpConfigItem[] = [
    {
      id: 'faq',
      label: t('components/nav:FAQ'),
      path: 'https://lps.housi.com/faq/',
      Icon: HelpIcon,
    },
    {
      id: 'envie-feedback',
      label: t('components/nav:FEEDBACK'),
      path: 'https://api.whatsapp.com/send?1=pt_BR&phone=5511916678278',
      Icon: MessageCircleIcon,
    },
  ]

  return {
    notLoggedHelpItems,
    loggedHelpItems,
  }
}

import * as c from '@chakra-ui/react'
import { retriever } from '@retriever-ui/system'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomColumn } from '../../../../components/layout'
import { convertToMoney } from '../../../../utils/monetary'
import { BookingContext } from '../../booking.context'
import Fees from './fees'
import PackHousi from './pack-housi'

export const RentPrice = ({ isModal }: { isModal?: boolean }) => {
  const { t } = useTranslation('property-detail/booking-resume')
  const { price, isSubscriptionUnavailable } = useContext(BookingContext)

  if (isSubscriptionUnavailable) {
    return (
      <CustomColumn topCustom={16} flex justify="space-between">
        <retriever.p
          fontSize={isModal ? 16 : 14}
          color="black.500"
          fontWeight={500}
        >
          {t('property-detail/booking-resume:RENT')}
        </retriever.p>
        <retriever.p
          fontSize={isModal ? 16 : 14}
          fontWeight={500}
          color="black.500"
          style={{ textDecorationLine: 'line-through' }}
        >
          {convertToMoney(price?.monthly?.netAmount)}
        </retriever.p>
      </CustomColumn>
    )
  }

  return (
    <CustomColumn topCustom={16} flex justify="space-between">
      <retriever.p
        fontSize={isModal ? 16 : 14}
        color="black.500"
        fontWeight={500}
      >
        {t('property-detail/booking-resume:RENT')}
      </retriever.p>
      <retriever.p
        fontSize={isModal ? 16 : 14}
        fontWeight={500}
        color="black.500"
      >
        {convertToMoney(price?.monthly?.netAmount)}
      </retriever.p>
    </CustomColumn>
  )
}

const Subscription = () => {
  return (
    <c.Flex direction="column" gap={2} mb={4}>
      <RentPrice />
      <Fees />
      <PackHousi />
    </c.Flex>
  )
}

export default Subscription

import { Box, Flex, Grid, retriever } from '@retriever-ui/react'
import { TFunction } from 'i18next'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { CustomButton } from 'src/components/button'
import useToRedirect from 'src/hooks/use-to-redirect'
import {
  FieldText,
  FieldTextPassword,
} from 'src/pages/profile-v2/components/field-text'
import ArrowLeft from 'src/pages/profile-v2/components/icons/arrow-left.icon'
import { validate } from 'src/utils/validate'
import * as yup from 'yup'

import { useAuth } from '../auth-context'
import GoogleAndFacebookButtons from '../components/google-facebook-login'
import { HousiLogoIcon } from '../icons/housi-logo.icon'

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    firstName: yup.string().required(t('formMessages:REQUIRED')),
    lastName: yup.string().required(t('formMessages:REQUIRED')),
    email: yup
      .string()
      .email(t('formMessages:EMAIL'))
      .required(t('formMessages:REQUIRED')),
    phones: yup.string().required(t('formMessages:REQUIRED')),
    password: yup
      .string()
      .required(t('formMessages:REQUIRED'))
      .min(6, t('formMessages:SIZE_MINIMUM', { number: 6 })),
    newsletter: yup.boolean(),
    conditions: yup.boolean().required(t('formMessages:REQUIRED')),
  })

export const FormSignUpOnly = ({ onFinish }: { onFinish?: () => void }) => {
  const { t } = useTranslation(['login', 'formMessages'])
  const { handleCreateAccount } = useAuth()

  const initialValue = {
    kind: 'natural_person',
    firstName: '',
    lastName: '',
    email: '',
    phones: '',
    password: '',
    newsletter: false,
    conditions: false,
  }

  const onSubmit = async ({
    conditions,
    newsletter,
    ...values
  }: typeof initialValue) => {
    const phoneFormat = values.phones.split(' ')
    const response = {
      ...values,
      phones: [
        {
          ddi: phoneFormat[0].replace(/\D/g, ''),
          ddd: phoneFormat[1].replace(/\D/g, ''),
          number: phoneFormat[2].replace(/\D/g, ''),
        },
      ],
      acceptConditions: newsletter,
      acceptTerms: conditions,
    }

    try {
      await handleCreateAccount(response)

      if (onFinish) {
        return onFinish()
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValue}
      validate={validate(validationSchema(t))}
      render={({ handleSubmit, submitting, values }) => (
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Flex direction="column" width="100%" gap="12px" alignItems="end">
            <Flex justifyContent="space-between" gap="16px">
              <FieldText
                name="firstName"
                label={t('login:NAME')}
                placeholder={t('login:NAME')}
              />
              <FieldText
                name="lastName"
                label={t('login:LAST_NAME')}
                placeholder={t('login:LAST_NAME')}
              />
            </Flex>
            <FieldText
              name="phones"
              label={t('login:PHONE')}
              mask="+99 (99) 99999-9999"
            />
            <FieldText
              name="email"
              label="Email"
              placeholder="email@email.com"
            />
            <FieldTextPassword
              name="password"
              placeholder="******"
              label={t('login:PASSWORD')}
              type="password"
            />
            <Flex alignContent="center" alignItems="center" gap="16px">
              <Field name="newsletter" type="checkbox">
                {({ input }) => (
                  <>
                    <retriever.input
                      {...(input as any)}
                      width="20px"
                      height="20px"
                      borderRadius="4px"
                    />
                    <retriever.p
                      color="black.600"
                      fontSize="14px"
                      style={{ fontWeight: 500 }}
                      lineHeight="21px"
                    >
                      {t('login:NEWSLETTER')}
                    </retriever.p>
                  </>
                )}
              </Field>
            </Flex>
            <Flex alignContent="center" alignItems="center" gap="16px">
              <Field name="conditions" type="checkbox">
                {({ input }) => (
                  <>
                    <retriever.input
                      {...(input as any)}
                      width="20px"
                      height="20px"
                      borderRadius="4px"
                    />
                    <retriever.p
                      fontSize="14px"
                      color="black.600"
                      style={{ fontWeight: 500 }}
                      lineHeight="21px"
                    >
                      {t('login:DECLARE')}
                      <Link to="/termos-e-condicoes" target="_blank">
                        <retriever.span
                          color="primary.500"
                          fontWeight="bold"
                          cursor="pointer"
                        >
                          {t('login:TERMS')}
                        </retriever.span>
                      </Link>
                    </retriever.p>
                  </>
                )}
              </Field>
            </Flex>
            <CustomButton
              type="submit"
              isLoading={submitting}
              disabled={!values.conditions || submitting}
              style={{ width: '100%', margin: '28px 0px' }}
            >
              {t('login:REGISTER')}
            </CustomButton>
          </Flex>
        </form>
      )}
    />
  )
}

const SignUp = () => {
  const { t } = useTranslation(['login', 'formMessages'])
  const { redirect_to } = useToRedirect()
  const navigate = useNavigate()

  return (
    <Box w="100%">
      <Flex direction="column" alignItems="center" gap="24px">
        <Box as="span" display={['none', 'block']}>
          <HousiLogoIcon />
        </Box>
        <Grid
          alignItems="center"
          justifyItems="center"
          gridTemplateColumns="40px 1fr 40px"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            maxW={40}
            h={40}
            role="button"
            cursor="pointer"
            onClick={() => navigate(`/auth/signin?redirect_to=${redirect_to}`)}
          >
            <ArrowLeft />
          </Flex>
          <retriever.p
            m="0"
            fontSize="20px"
            color="black.500"
            fontWeight="bold"
          >
            {t('login:NEW_ACCOUNT')}
          </retriever.p>
        </Grid>
        <Box height="1px" width="100%" backgroundColor="black.200" />
        <retriever.p color="black.500" style={{ fontWeight: 500 }}>
          {t('login:EXPERIENCE')}
        </retriever.p>
        <FormSignUpOnly />
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          gap="24px"
        >
          <GoogleAndFacebookButtons />
          <retriever.p
            role="button"
            color="primary.500"
            fontWeight="bold"
            cursor="pointer"
            fontSize="14px"
            onClick={() => navigate(`/auth/signin?redirect_to=${redirect_to}`)}
          >
            {t('login:ALREADY_HAVE')}
          </retriever.p>
        </Flex>
      </Flex>
    </Box>
  )
}
export default SignUp
